// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/order/config.ts"
);
import.meta.hot.lastModified = "1739400928944.6453";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/orders',
  apiPath: '/api/order',
}

export enum OrderState {
  DRAFT = 'Draft',
  PAYMENT_SETTLED = 'PaymentSettled',
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  ARRANGING_PAYMENT = 'ArrangingPayment',
  ADDING_ITEMS = 'AddingItems',
  ARRANGING_ADDITIONAL_PAYMENT = 'ArrangingAdditionalPayment',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
}

export enum AssignmentStrategy {
  'FULL' = 'FULL', // assign to all instances
  'DATE_RANGE' = 'DATE_RANGE', // assign to only days which are permitted instances
  'PART_TIME' = 'PART_TIME', // assign to all instances which are in week
  'WEEK_DAYS' = 'WEEK_DAYS', // assign to days which are permitted instances
}

export const strategyOptions = [
  {
    label: 'Full / Default',
    value: AssignmentStrategy.FULL,
  },
  {
    label: 'Date Range',
    value: AssignmentStrategy.DATE_RANGE,
  },
  {
    label: 'Part Time',
    value: AssignmentStrategy.PART_TIME,
  },
  {
    label: 'Week Days',
    value: AssignmentStrategy.WEEK_DAYS,
  },
]
